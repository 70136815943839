'use client' // Error components must be Client Components

import Bugsnag from '@bugsnag/js'
import ContentHeader from '@/components/layout/ContentHeader'
import LinkButton from '@/components/router/LinkButton'
import { ReactNode, useEffect } from 'react'
import { rootPath } from 'shared/urlHelpers'

export interface ErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

const Error = ({ error, reset }: ErrorProps): ReactNode => {
  useEffect(() => {
    Bugsnag.notify(`Digest: ${error.digest} Error: ${error.message}`)
  }, [])

  return (
    <div className="container-white">
      <ContentHeader title="500" />
      <p className="mb-4">
        Es ist ein Fehler aufgetreten. Bitte versuchen sie es später noch
        einmal.
      </p>
      <LinkButton href={rootPath()}>Zurück zur Startseite</LinkButton>
    </div>
  )
}

export default Error
